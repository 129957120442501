import * as React from "react"
import "./who.scss"
import { createMarkup } from "../../../utils"


export default function WhoWeAreVendor({ title, name, description ,data}) {
  return (
    <>
      <section className="who-we-are-vendor">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-8 left-col">
            <div className="big-title h2">{name}</div>
              <div className="small-title">{title}</div>
            </div>
            <div className="col-dt-4 right-col">
            <div className="button-wrapper">
                        
                            
                        </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
