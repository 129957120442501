import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import GetintouchVendor from '../components/GetinTouchVendor'
import Layout from "../components/layout"
import WhoWeAreVendor from "../components/WhoWeAreVendor"
import VendorFirstComponent from "../components/VendorFirstComponent"
import LifeSliderVendor from "../components/LifeSliderVendor"
import CallToActionBannerVendor from "../components/CallToActionBannerVendor"
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import WhoWeAreButton from "../components/WhoWeAreButton"
import WhoWeAreWorkZone from "../components/WhoWeAreWorkZone"
import LifeSliderVendorNew from "../components/LifeSliderVendorNew"
import ComboWrapperProcurement from "../components/ComboWrapperProcurement"
export default function Vendor(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
     
      <WhoWeAreVendor
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
          data={data?.wpPage?.vendorData?.callToActionArea}
        /> 
       
       <LifeSliderVendor data={data.wpPage.vendorData} locale={locale}/>
        <ComboWrapperProcurement data={data.wpPage.vendorData} locale={locale}/>
        <GetintouchVendor data={data?.wpPage?.vendorData?.vendorContactForm} locale={locale}/>


      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query vendor($databaseId: Int) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      title
      pageSubtitle {
        fieldGroupName
        subtitle
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      pageDescription {
        pageDescription
      }
    
      vendorData {
        popupHeaderText {
          projectName
          submittingDate
          deadlineForSubmitting
          competitionLink
          status
          contactEmail
          projectBrief
          supplierNote
          deadline
          location
        }
        apiUrl
        categoryApiUrl
        callToActionArea {
          title
          description
          buttonTextRegister
          buttonTextLogin
          buttonLinkRegister
          buttonLinkLogin
        }
        mobileFeaturedImage {
          localFile{
            publicURL
          }
        }
        sliderData {
          image {
            localFile {
              publicURL
            }
          }
          text
          title
        }
        documents {
          userManualText
          requiredDocumentText
          ndaText
          codeOfEthicsText
          conflictOfInterestText
          requiredDocumentFile {
            localFile {
              publicURL
            }
          }
          userManualFile {
            localFile {
              publicURL
            }
          }
          ndaFile {
            localFile {
              publicURL
            }
          }
          codeOfEthicsFile {
            localFile {
              publicURL
            }
          }
          privacyPolicyFile {
            localFile {
              publicURL
            }
          }
          conflictOfInterestFile {
            localFile {
              publicURL
            }
          }
        }
        vendorContactForm
      }
    }
  
  }
`
