import React from "react"
import "./lifeslider.scss"

// import Swiper core and required modules
import { EffectFade, Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import SliderNavigation from '../SharedComponents/SliderNavigation'

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import {  useStaticQuery, graphql } from "gatsby"
import { createMarkup, string_translation } from "../../../utils"

export default function LifeSliderVendor(props) {
  const swiperRef = React.useRef(null)
  const { data, locale } = props

  const { stringTranslations } = useStaticQuery(graphql`
  query {
    stringTranslations {
      privacy_policy_text_ar:translateString(language: AR, string: " أقر بقراءتي وفهمي وموافقتي على الوثائق الداعمة أعلاه")
      privacy_policy_text_en:translateString(language: EN, string: " I acknowledge that I have read, understand the above attached files ")
      privacy_policy_link_ar:translateString(language: AR, string: "الشروط والأحكام")
      privacy_policy_link_en:translateString(language: EN, string: "terms and conditions")
   
    }
  }
`)

  const shouldRenderArrows = data?.sliderData?.length > 1

  function onChangeTerms(e){
if(e.target.checked){
  document.getElementById("loginForm").classList.remove('disabled');
  document.getElementById("registerForm").classList.remove('disabled');
}else{
  document.getElementById("loginForm").classList.add('disabled');
  document.getElementById("registerForm").classList.add('disabled');
}
  }

  return (
    <>
      <section className="divers-slider-section-vendor">
        <div className="container">
          <Swiper
            // install Swiper modules
            modules={[EffectFade, Navigation, Pagination]}
            pagination={{
              clickable: true,
            }}
            id="divers-swiper"
            slidesPerView={1}
            slidesPerGroup={1}
            grabCursor={"true"}
            effect={"fade"}
            ref={swiperRef}
            dir={locale === "en" ? "ltr" : "rtl"}
          >
            
            {data.sliderData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="col-group">
                <div className="col-dt-6">
                  <div className="wrapper">
                    <div className="img-wrapper">
                      <img src={item.image?.localFile?.publicURL} alt={item.title} />
                    </div>
                  </div>
                </div>
                <div className="col-dt-6">
                  <div className="text-wrapper">
                    <div className="big-title h2">
                      {item.title}
                    </div>
                    <div className="desc" dangerouslySetInnerHTML={createMarkup(item.text)}>
                    </div>
                    <div className="documentsLink">
                    <br></br>
                    <a href={data?.documents?.userManualFile?.localFile?.publicURL}   rel="noopener noreferrer">
                        <img src="/images/infographics/download.svg" alt="Download" />    <span>{data?.documents?.userManualText}</span>
                      </a>
                   
                    <a href={data?.documents?.requiredDocumentFile?.localFile?.publicURL}   rel="noopener noreferrer">
                        <img src="/images/infographics/download.svg" alt="Download" /> <span>{data?.documents?.requiredDocumentText}</span>
                    </a>
                    <a href={data?.documents?.ndaFile?.localFile?.publicURL}   rel="noopener noreferrer">
                        <img src="/images/infographics/download.svg" alt="Download" /> <span>{data?.documents?.ndaText}</span>
                    </a>
                    <a href={data?.documents?.conflictOfInterestFile?.localFile?.publicURL}   rel="noopener noreferrer">
                        <img src="/images/infographics/download.svg" alt="Download" /> <span>{data?.documents?.conflictOfInterestText}</span>
                    </a>
                    <a href={data?.documents?.codeOfEthicsFile?.localFile?.publicURL}   rel="noopener noreferrer">
                        <img src="/images/infographics/download.svg" alt="Download" /> <span>{data?.documents?.codeOfEthicsText}</span>
                    </a>
                      <br></br>
                      <input  type="checkbox" value="1" onChange={e => onChangeTerms(e)} /> {string_translation(stringTranslations, "privacy_policy_text", locale)}
                      {/*<a href={data?.documents?.privacyPolicyFile?.localFile?.publicURL} className="termLink"   rel="noopener noreferrer">*/}
                        {/*  {string_translation(stringTranslations, "privacy_policy_link", locale)}*/}
                        {/*</a>*/}
                      </div>
                    <div class="center-div">
                    <div className="button-wrapper">
                            <div className="apply-btn">
                           

                            <a href={data?.callToActionArea?.buttonLinkLogin} id="loginForm" className="disabled"  target="_blank" rel="noopener noreferrer">
                            {data?.callToActionArea?.buttonTextLogin}
                            </a>
                            &nbsp;&nbsp;
                            <a href={data?.callToActionArea?.buttonLinkRegister} id="registerForm" className="disabled"  target="_blank" rel="noopener noreferrer">
                            {data?.callToActionArea?.buttonTextRegister}
                            </a>

</div>
</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            ))}
            {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}
          </Swiper>
        </div>
      </section>
    </>
  )
}
